import React, { useState } from "react";
import { Link } from "gatsby";
import Cursor from "../../components/cursor";
import style from "./blogger.module.css";

export default () => {
  const windowWidth =
    typeof window !== "undefined" ? document.documentElement.clientWidth : 0;
  let [index, setIndex] = useState(0);

  function handleActive(val) {
    return () => {
      setIndex(val);
    };
  }

  return (
    <div className={`${style.slideOne} d-flex`}>
      <Cursor></Cursor>
      <div className={style.designerHeader}>
        <Link to="/">
          <div className={style.icyIcon}></div>
        </Link>
        <ul className={style.tabGroup}>
          <li
            className={`${index === 0 ? style.active : ""}`}
            onClick={handleActive(0)}
          >
            • STAR
          </li>
          <li
            className={`${index === 1 ? style.active : ""}`}
            onClick={handleActive(1)}
          >
            • KOL
          </li>
        </ul>
      </div>
      {windowWidth > 576 && (
        <>
          {index === 0 ? (
            <div className={style.bloggerWrap}>
              <img
                src="https://image3.ichuanyi.cn/Files/73/0b/7a/730b7a9486178a812a9c13b8a7dd033804ee8517_211300.jpg"
                alt="秦岚，angelababy，张天爱"
              />
              <img
                src="https://image3.ichuanyi.cn/Files/3a/eb/75/3aeb75d3878b18f9828aaed1dafe95bce5848d82_269689.jpg"
                style={{ marginTop: "40px" }}
                alt="杨紫，迪丽热巴，小松菜奈，蒋勤勤，陈意涵，陈晓纭，周洁琼，沈月，唐艺昕，陈都灵，袁姗姗，陈小纭"
              />
              <img
                src="https://image3.ichuanyi.cn/Files/72/9f/d8/729fd8beb7fe1d5c444e8ce8dcb9cbca8f19df57_309285.jpg"
                alt="郑合惠子，王丽坤，陈泇文，陈昊宇，曹曦月，柴蔚，冉莹颖，张碧晨，索镇·索朗卓嘎"
              />
              <img
                src="https://image3.ichuanyi.cn/Files/b6/54/28/b65428382f95e2a0de4386a5eda3bc47055f02f2_298897.jpg"
                alt="何泓姗，陈瑶，李曼，曾一萱，陈雨锶，郭静，代斯，朱圣祎，屈菁菁，姜瑞佳，雎晓雯"
              />
            </div>
          ) : (
            <div className={style.bloggerWrap}>
              <img
                style={{ position: "relative", top: "1px" }}
                src="https://image3.ichuanyi.cn/Files/0b/b5/9d/0bb59db72bfbd787c7c424b0fbb356a1657ef221_327862.jpg"
                alt="敢姑娘，黄楚桐，lulucialucia，邹sisi，自由行走的猫头樱，Elin_顾零一，就是言言啊_"
              />
              <img
                src="https://image3.ichuanyi.cn/Files/28/3b/17/283b172ff84b0e4ac72b0243b0ba516222f2e476_316983.jpg"
                alt="一只时髦哔哔，黄楚桐，张九黎Jolie，Sswdm，VC大魔王啊，雀斑鲸鱼，卫_公子，阿肆，CeeeChen，秋天的亦然，作作小姐Gracious，鲨鱼小饱饱"
              />
              <img
                src="https://image3.ichuanyi.cn/Files/77/4b/eb/774beb15965f0f0c2b08e7995e34e369e643cba9_321224.jpg"
                alt="赫兹，卫_公子，鱼干游鱼无鳞刺，JolieHi啾黎，水妖vv，焱焱煜焰Kiwi，逸筱筱筱，Ann大爷"
              />
              <img
                style={{
                  position: "relative",
                  top: "-14px",
                  left: "2px",
                  transform: "scale(0.996)",
                }}
                src="https://image3.ichuanyi.cn/Files/c3/5d/35/c35d352bc33d6957f1161f8abfdc9747adeb38a7_147059.jpg"
                alt="卫_公子，WOODS木子V，VC大魔王啊，O96D"
              />
            </div>
          )}
        </>
      )}

      {windowWidth <= 576 && (
        <>
          {index === 0 ? (
            <div className={style.bloggerWrap}>
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/91/bc/41/91bc415ecc8ab97ff26cd604a36abe4b1f48e64f_134322.jpg"
                alt="杨紫，迪丽热巴，陈意涵，陈小纭，唐艺昕"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/27/98/a2/2798a2f39716772fc7dc3f09014905493b731939_161592.jpg"
                alt="郑合惠子，王丽坤，陈泇文，柴蔚，冉莹颖"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/e1/5f/a1/e15fa137c2f9f33a71bdd41fb48aee4424e5fd1c_128431.jpg"
                alt="王晓晨，李曼，陈瑶，代斯，郭静，屈菁菁"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/ac/6e/3f/ac6e3fe1cafa768c43ef29a0f1e6620db48d4e9a_111645.jpg"
                alt="小松菜奈，蒋勤勤，周洁琼，沈月，陈都灵，袁姗姗"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/34/65/ca/3465ca3aa1daab0144ad9af3c2e17d9a4268c019_134312.jpg"
                alt="陈昊宇，曹曦月，Sswdm，何泓姗"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/9b/63/a1/9b63a1938bf0e2d859e5d6ec3fef296b93686aaf_141308.jpg"
                alt="曾一萱，姜瑞佳，雎晓雯"
              />
            </div>
          ) : (
            <div className={style.bloggerWrap}>
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/4a/0f/07/4a0f074a77201f4c3180b483573948e2948b5c68_146786.jpg"
                alt="邹sisi，自由行走的猫头樱，一只时髦哔哔，黄楚桐，VC大魔王啊，雀斑鲸鱼"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/1a/ea/22/1aea22c1c4a7f99e66854a81965d9c25134717c8_150153.jpg"
                alt="CeeeChen，秋天的亦然，赫兹，卫_公子"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/f0/d2/01/f0d201fc69760d74948b9813fdb0b65500c7d139_157745.jpg"
                alt="水妖vv，焱焱煜焰Kiwi，卫_公子，Elin_顾零一，就是言言啊_"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/0e/98/9a/0e989aaef55ce8d57573d0705b8293ee52637613_186859.jpg"
                alt="张九黎Jolie，Sswdm，卫_公子，阿肆，作作小姐Gracious，鲨鱼小饱饱"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/7e/f0/dd/7ef0dd245aaf15fd10ff1f3d4b9371ca85410168_162645.jpg"
                alt="鱼干游鱼无鳞刺，JolieHi啾黎，逸筱筱筱，Ann大爷"
              />
              <img
                width="640"
                height="360"
                src="https://image3.ichuanyi.cn/Files/8d/8e/cf/8d8ecfea2685e2c6677e574a2c618dc376486b93_62914.jpg"
                alt="VC大魔王啊，O96D"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
